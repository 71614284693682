import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpApiService } from '@recruitee/http';
import type {
  ReferralsPortalApplicationParams,
  ReferralsPortalApplication,
  ReferralsPortalApplicationPayloadI,
} from '@recruitee/referrals-types';

@Injectable({ providedIn: 'root' })
export class ApplicationsHttpService {
  constructor(private httpApi: HttpApiService) {}

  public loadApplications(
    payload?: ReferralsPortalApplicationParams,
  ): Observable<ReferralsPortalApplication[]> {
    return this.httpApi.get('/applications', payload).pipe(map(data => data.applications));
  }

  public loadOfferApplications(
    offerId: string,
    payload?: ReferralsPortalApplicationParams,
  ): Observable<ReferralsPortalApplication[]> {
    return this.httpApi
      .get(`/applications/${offerId}`, payload)
      .pipe(map(data => data.applications));
  }

  public sendApplication(payload: ReferralsPortalApplicationPayloadI): Observable<any> {
    return this.httpApi.post('/applications', null, { application: payload });
  }
}
