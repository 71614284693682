export const uiTranslations = {
  en: () => import( /* webpackPrefetch: true */
  /* webpackChunkName: "ng-locale-en" */
  './en.json').then(translations => ({
    en: translations.default
  })),
  de: () => import( /* webpackChunkName: "ng-locale-de" */
  './de.json').then(translations => ({
    de: translations.default
  })),
  fr: () => import( /* webpackChunkName: "ng-locale-fr" */
  './fr.json').then(translations => ({
    fr: translations.default
  })),
  nl: () => import( /* webpackChunkName: "ng-locale-nl" */
  './nl.json').then(translations => ({
    nl: translations.default
  })),
  fi: () => import( /* webpackChunkName: "ng-locale-fi" */
  './fi.json').then(translations => ({
    fi: translations.default
  })),
  sv: () => import( /* webpackChunkName: "ng-locale-sv" */
  './sv.json').then(translations => ({
    sv: translations.default
  }))
};
