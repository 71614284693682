import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ProfileEffects } from './store/profile-effects.service';
import { ProfileNamespace, profileReducer } from './store/profile.store';

@NgModule({
  imports: [
    StoreModule.forFeature(ProfileNamespace, profileReducer),
    EffectsModule.forFeature([ProfileEffects]),
  ],
})
export class ProfileDataModule {}
