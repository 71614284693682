import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpApiService } from '@recruitee/http';

import { ReferralsPortalProfile, ReferralsPortalProfileUpdate } from '../types';

@Injectable({ providedIn: 'root' })
export class ProfileHttpService {
  constructor(private httpApi: HttpApiService) {}

  public loadProfile(): Observable<ReferralsPortalProfile> {
    return this.httpApi.get('/profile').pipe(map(res => res.referrer));
  }

  public updateProfile(payload: ReferralsPortalProfileUpdate): Observable<ReferralsPortalProfile> {
    return this.httpApi
      .patch('/profile', null, { profile: payload })
      .pipe(map(res => res.referrer));
  }
}
