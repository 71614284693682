import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TermsEffects } from './store/terms-effects.service';
import { TermsNamespace, termsReducer } from './store/terms.store';

@NgModule({
  imports: [
    StoreModule.forFeature(TermsNamespace, termsReducer),
    EffectsModule.forFeature([TermsEffects]),
  ],
})
export class TermsDataModule {}
