import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { OfferAssignmentsHttpService } from './offer-assignments-http.service';
import { OfferAssignmentsActions } from './offer-assignments.store';
import { OfferFiltersActions } from './offer-filters.store';

@Injectable({ providedIn: 'root' })
export class OfferAssignmentsEffects {
  public loadOfferAssignments = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferAssignmentsActions.fetchCollection),
      mergeMap(({ payload }) =>
        this.offerAssignmentsHttpService.loadOfferAssignments(payload.params).pipe(
          map(data =>
            OfferAssignmentsActions.fetchCollectionSuccess({
              payload: {
                data: data.offerAssignments,
                scope: payload.scope,
                replace: payload.replace,
                meta: data.meta,
              },
            }),
          ),
          catchError(error =>
            of(OfferAssignmentsActions.fetchCollectionFailed({ payload: error })),
          ),
        ),
      ),
    ),
  );

  public loadOfferAssignmentsDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferAssignmentsActions.fetchOne),
      mergeMap(({ payload }) =>
        this.offerAssignmentsHttpService.loadOfferAssignmentDetails(payload.id).pipe(
          map(({ offerAssignment }) =>
            OfferAssignmentsActions.fetchOneSuccess({
              payload: { data: offerAssignment },
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(OfferAssignmentsActions.fetchOneFail({ payload: { error } })),
          ),
        ),
      ),
    ),
  );

  public loadOfferFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferFiltersActions.fetch),
      mergeMap(() =>
        this.offerAssignmentsHttpService.loadOfferFilters().pipe(
          map(data =>
            OfferFiltersActions.fetchSuccess({
              payload: data,
            }),
          ),
          catchError(error => of(OfferFiltersActions.fetchFailed({ payload: error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private offerAssignmentsHttpService: OfferAssignmentsHttpService,
  ) {}
}
