import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, tap, mergeMap } from 'rxjs/operators';

import { AuthService } from '@recruitee/http';

import { environment } from '../../../../../environments/environment';
import { AuthConfirmationSuccessPayload } from '../types';
import { CoreHttpService } from './core-http.service';
import { CoreEntityActions } from './core.store';

@Injectable({ providedIn: 'root' })
export class CoreEffects {
  public navigateToRecruitee$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreEntityActions.navigateToRecruitee),
        tap(() => window.open(environment.webAppUrl, '_blank')),
      ),
    { dispatch: false },
  );

  public signInWithRecruitee$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreEntityActions.signInWithRecruitee),
        tap(() => {
          const port = location.port ? `:${location.port}` : '';
          this.authService.redirectToAuth(`${location.protocol}//${location.hostname}${port}/`);
        }),
      ),
    { dispatch: false },
  );

  public signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreEntityActions.signIn),
      mergeMap(({ payload }) =>
        this.coreHttpService.signIn(payload).pipe(
          tap(res => this.authService.saveAccessTokenInCookie(res.accessToken, false)),
          map(() => CoreEntityActions.signInSuccess()),
          catchError(error => of(CoreEntityActions.signInFail({ payload: error }))),
        ),
      ),
    ),
  );

  public logOutWithRecruitee$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreEntityActions.logOutWithRecruitee),
        tap(() => {
          this.authService.clearAccessToken();
          this.authService.redirectToSignOut();
        }),
      ),
    { dispatch: false },
  );

  public logOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreEntityActions.logOut),
      mergeMap(() =>
        this.coreHttpService.logOut().pipe(
          tap(() => this.authService.clearAuthCookies()),
          map(() => CoreEntityActions.logOutSuccess()),
        ),
      ),
    ),
  );

  public signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreEntityActions.signUp),
      mergeMap(({ payload }) =>
        this.coreHttpService.signUp(payload).pipe(
          tap(res => {
            if (res?.accessToken)
              return this.authService.saveAccessTokenInCookie(res.accessToken, false);
          }),
          map(() => CoreEntityActions.signUpSuccess()),
          catchError(error => of(CoreEntityActions.signUpFail({ payload: error }))),
        ),
      ),
    ),
  );

  public authorizeConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreEntityActions.authorizeConfirmation),
      mergeMap(({ payload }) =>
        this.coreHttpService.authorizeConfirmation(payload).pipe(
          tap((res: AuthConfirmationSuccessPayload) =>
            this.authService.saveAccessTokenInCookie(res.accessToken, false),
          ),
          map(res => CoreEntityActions.authorizeConfirmationSuccess({ payload: res })),
          catchError(error => of(CoreEntityActions.authorizeConfirmationFail({ payload: error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private coreHttpService: CoreHttpService,
    private authService: AuthService,
  ) {}

  public ngrxOnInitEffects(): Action {
    return CoreEntityActions.init();
  }
}
