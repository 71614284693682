import { Inject } from '@angular/core';

import { pipe, throwError, UnaryFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { COMPANY_ID } from '@recruitee/common';
import { AuthService, HttpApiErrorHandlerService } from '@recruitee/http';

export class ReferralsHttpApiErrorHandlerService implements HttpApiErrorHandlerService {
  constructor(
    private authService: AuthService,
    @Inject(COMPANY_ID) private currentCompanyId: number,
  ) {}

  public handleError(url: string): UnaryFunction<any, any> {
    return pipe(
      catchError(error => {
        if (401 === error.status) {
          this.authService.onUnauthorized(error);
        } else if (403 === error.status) {
          this.authService.onForbidden(error, this.currentCompanyId, url);
        }
        return throwError(error);
      }),
    );
  }
}
