import { Inject, Injectable } from '@angular/core';

import {
  APP_CONFIG,
  AUTH_METHOD,
  AUTH_TOKEN,
  IAppConfig,
  LocalStorageService,
} from '@recruitee/common';
import { HttpRequestData, IdentityHeader, IdentityHeaders } from '@recruitee/http';
import { WindowRef } from '@recruitee/user-interface';

import { environment } from '../../environments/environment';

@Injectable()
export class ReferralsRequestData extends HttpRequestData {
  private readonly subdomain: string;

  constructor(
    private windowRef: WindowRef,
    private localStorageService: LocalStorageService,
    @Inject(APP_CONFIG) private config: IAppConfig,
    @Inject(AUTH_METHOD) public authMethod: string,
    @Inject(AUTH_TOKEN) private authToken: string,
  ) {
    super();
    this.subdomain = environment.resolveSubdomainFromHost
      ? this.resolveSubdomainFromHost()
      : this.promptForSubdomain();
  }

  public buildUrl(path: string): string {
    return `${this.config.apiUrl}/${this.subdomain}` + path;
  }

  public provideAuthHeaders(): { Authorization: string } | {} {
    return this.authMethod && this.authToken
      ? { Authorization: `${this.authMethod} ${this.authToken}` }
      : {};
  }

  public provideIdentityHeaders(): IdentityHeaders {
    return {
      [IdentityHeader.Client]: this.config.isE2e ? 'Referrals - e2e' : 'Referrals',
      [IdentityHeader.Env]: environment.environmentName,
    };
  }

  private resolveSubdomainFromHost(): string {
    const {
      location: { hostname },
    } = this.windowRef.nativeWindow;
    return hostname.split('.')[0];
  }

  private promptForSubdomain(): string {
    const LS_KEY = 'referrals_subdomain';
    const valueFromLS = this.localStorageService.get(LS_KEY);

    if (valueFromLS) {
      return valueFromLS;
    }

    const subdomain = prompt('Type referrals subdomain here')!;
    this.localStorageService.set(LS_KEY, subdomain);

    return subdomain;
  }
}
