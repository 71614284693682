import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { RecaptchaModule } from 'ng-recaptcha';

import { SharedModule } from '../shared/shared.module';
import { MainViewModule } from './main-view/main-view.module';
import { TitleService } from './services/title.service';

@NgModule({
  imports: [CommonModule, MainViewModule, RecaptchaModule, RouterModule, SharedModule],
})
export class CoreModule {
  constructor(private titleService: TitleService) {}
}
