import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpApiService } from '@recruitee/http';

import { ForgotPasswordPayload, ResetPasswordPayload } from '../types';

@Injectable({ providedIn: 'root' })
export class ReferrerHttpService {
  constructor(private httpApi: HttpApiService) {}

  public forgotPassword(payload: ForgotPasswordPayload): Observable<void> {
    return this.httpApi.post('/password/request_reset', null, {
      requestReset: payload,
    });
  }

  public resetPassword(payload: ResetPasswordPayload): Observable<void> {
    return this.httpApi.post('/password/authorize_reset', null, {
      authorizeReset: payload,
    });
  }
}
