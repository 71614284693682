import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { OfferAssignmentsEffects } from './store/offer-assignments-effects.service';
import {
  OfferAssignmentsNamespace,
  offerAssignmentsReducer,
} from './store/offer-assignments.store';
import { OfferFiltersNamespace, offerFiltersReducer } from './store/offer-filters.store';

@NgModule({
  imports: [
    StoreModule.forFeature(OfferAssignmentsNamespace, offerAssignmentsReducer),
    StoreModule.forFeature(OfferFiltersNamespace, offerFiltersReducer),
    EffectsModule.forFeature([OfferAssignmentsEffects]),
  ],
})
export class OfferAssignmentsDataModule {}
