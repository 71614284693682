export const appTranslations = {
  en: () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "ng-locale-en" */
      './en.json'
    ).then(translations => ({
      en: translations.default,
    })),
  de: () =>
    import(
      /* webpackChunkName: "ng-locale-de" */
      './de.json'
    ).then(translations => ({ de: translations.default })),
  fr: () =>
    import(
      /* webpackChunkName: "ng-locale-fr" */
      './fr.json'
    ).then(translations => ({ fr: translations.default })),
  nl: () =>
    import(/* webpackChunkName: "ng-locale-nl" */ './nl.json').then(translations => ({
      nl: translations.default,
    })),
  fi: () =>
    import(/* webpackChunkName: "ng-locale-fi" */ './fi.json').then(translations => ({
      fi: translations.default,
    })),
  sv: () =>
    import(/* webpackChunkName: "ng-locale-sv" */ './sv.json').then(translations => ({
      sv: translations.default,
    })),
};

export const wysiwygTranslations = {
  en: () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "ng-locale-en" */
      '@recruitee/prosemirror/locales/src/en.json'
    ).then(t => ({ en: t.default })),
  de: () =>
    import(
      /* webpackChunkName: "ng-locale-de" */
      '@recruitee/prosemirror/locales/src/de.json'
    ).then(t => ({ de: t.default })),
  fr: () =>
    import(
      /* webpackChunkName: "ng-locale-fr" */
      '@recruitee/prosemirror/locales/src/fr.json'
    ).then(t => ({ fr: t.default })),
  nl: () =>
    import(
      /* webpackChunkName: "ng-locale-nl" */
      '@recruitee/prosemirror/locales/src/nl.json'
    ).then(t => ({ nl: t.default })),
  fi: () =>
    import(
      /* webpackChunkName: "ng-locale-fi" */
      '@recruitee/prosemirror/locales/src/fi.json'
    ).then(t => ({ fi: t.default })),
  sv: () =>
    import(
      /* webpackChunkName: "ng-locale-sv" */
      '@recruitee/prosemirror/locales/src/sv.json'
    ).then(t => ({ sv: t.default })),
};
