import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpApiService } from '@recruitee/http';
import type { ReferralsTerm } from '@recruitee/referrals-types';

@Injectable({ providedIn: 'root' })
export class TermsHttpService {
  constructor(private httpApi: HttpApiService) {}

  public loadTerms(): Observable<ReferralsTerm[]> {
    return this.httpApi.get('/terms').pipe(map(data => data.terms));
  }
}
