import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';

import { FeatureDiscoveryHttpService } from './feature-discovery-http.service';
import { FeatureDiscoveryEntityActions } from './feature-discovery.store';

@Injectable({ providedIn: 'root' })
export class FeatureDiscoveryEffects {
  public markFeatureAsDiscovered$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureDiscoveryEntityActions.markFeatureAsDiscovered),
      mergeMap(({ payload }) =>
        this.featureDiscoveryHttpService
          .markFeatureAsDiscovered(payload)
          .pipe(
            map(data =>
              FeatureDiscoveryEntityActions.markFeatureAsDiscoveredSuccess({ payload: data }),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private featureDiscoveryHttpService: FeatureDiscoveryHttpService,
  ) {}
}
