import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ReferrerEffects } from './store/referrer-effects.service';
import { ReferrerNamespace, referrerReducer } from './store/referrer.store';

@NgModule({
  imports: [
    StoreModule.forFeature(ReferrerNamespace, referrerReducer),
    EffectsModule.forFeature([ReferrerEffects]),
  ],
})
export class ReferrerDataModule {}
