import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ApplicationsEffects } from './store/applications-effects.service';
import { ApplicationsNamespace, applicationsReducer } from './store/applications.store';

@NgModule({
  imports: [
    StoreModule.forFeature(ApplicationsNamespace, applicationsReducer),
    EffectsModule.forFeature([ApplicationsEffects]),
  ],
})
export class ApplicationsDataModule {}
