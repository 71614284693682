import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import moment from 'moment-timezone';

import { AUTH_METHOD, AUTH_TOKEN, ENVIRONMENT } from '@recruitee/common';
import { patchMoment } from '@recruitee/i18n';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { authService, authStrategy } from './main-deps';

import './vendor';

// @ts-ignore
window['moment'] = moment;
import 'moment/locale/de';
import 'moment/locale/nl';
import 'moment/locale/fr';
import 'moment/locale/fi';
import 'moment/locale/sv';
patchMoment(moment);
moment.locale('en');

if (environment.angularProdMode) {
  enableProdMode();
}

const getAuthToken = async (): Promise<string | void> => {
  try {
    return await authService.getAccessToken();
  } catch (err) {
    console.warn(`~~~~~~ getAccessToken ERROR ~~~~~~`);
    console.error(err);
  }
};

const bootstrap = async () => {
  const authToken = await getAuthToken();

  if (!authToken) {
    authStrategy.onAuthorizationFailed();
  }

  const ngModule: NgModuleRef<any> = await platformBrowserDynamic([
    { provide: AUTH_TOKEN, useValue: authToken },
    { provide: AUTH_METHOD, useValue: 'Bearer' },
    { provide: ENVIRONMENT, useValue: environment },
  ]).bootstrapModule(AppModule, {
    ngZone: 'noop',
  });

  return ngModule;
};

bootstrap();
