import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { TermsHttpService } from './terms-http.service';
import { TermsActions } from './terms.store';

@Injectable({ providedIn: 'root' })
export class TermsEffects {
  public loadTermsCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TermsActions.fetchCollection),
      mergeMap(({ payload }) =>
        this.termsHttpService.loadTerms().pipe(
          map(data =>
            TermsActions.fetchCollectionSuccess({
              payload: { data },
            }),
          ),
          catchError(error => of(TermsActions.fetchCollectionFailed({ payload: error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private termsHttpService: TermsHttpService,
  ) {}
}
