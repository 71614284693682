import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FeatureDiscoveryEffects } from './store/feature-discovery-effects.service';
import {
  FeatureDiscoveryNamespace,
  featureDiscoveryReducer,
} from './store/feature-discovery.store';

@NgModule({
  imports: [
    StoreModule.forFeature(FeatureDiscoveryNamespace, featureDiscoveryReducer),
    EffectsModule.forFeature([FeatureDiscoveryEffects]),
  ],
})
export class FeatureDiscoveryDataModule {}
