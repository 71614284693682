import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpApiService } from '@recruitee/http';

import {
  AuthConfirmationSuccessPayload,
  AuthorizeConfirmationPayload,
  SignInPayload,
  SignUpPayload,
} from '../types';

@Injectable({ providedIn: 'root' })
export class CoreHttpService {
  constructor(private httpApi: HttpApiService) {}

  public signIn(payload: SignInPayload): Observable<AuthConfirmationSuccessPayload> {
    return this.httpApi.post('/sign_in', null, payload);
  }

  public logOut(): Observable<void> {
    return this.httpApi.get('/sign_out');
  }

  public signUp(payload: SignUpPayload): Observable<any> {
    return this.httpApi.post('/registration', null, payload);
  }

  public authorizeConfirmation(
    payload: AuthorizeConfirmationPayload,
  ): Observable<AuthConfirmationSuccessPayload> {
    return this.httpApi.post('/registration/authorize_confirmation', null, payload);
  }
}
