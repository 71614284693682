import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment-timezone';
import { combineLatest, merge, Observable, Subject } from 'rxjs';
import { filter, first, map, switchMap, take } from 'rxjs/operators';

import { AUTH_TOKEN, filterBoolean } from '@recruitee/common';
import { HTTP_STATUS } from '@recruitee/http';
import {
  I18nService,
  PhraseAppTranslations,
  PhraseAppTranslationService,
  phraseAppTranslationsLoader,
  SupportedTranslations,
} from '@recruitee/i18n';
import { uiTranslations } from '@recruitee/user-interface/locales';

import { appTranslations, wysiwygTranslations } from '../locales';
import { ConditionsActions } from './features/conditions/data/store/conditions.store';
import { CoreEntityActions } from './features/core/data/store/core.store';
import {
  ProfileEntityActions,
  profileSelectors,
} from './features/profile/data/store/profile.store';
import {
  ProgramEntityActions,
  programSelectors,
} from './features/program/data/store/program.store';
import { TermsActions } from './features/terms/data/store/terms.store';

@Injectable({ providedIn: 'root' })
export class AppInitializationService {
  private protectedDataLoaded$ = new Subject<boolean>();
  private publicDataLoaded$ = new Subject<boolean>();

  constructor(
    private store: Store,
    private router: Router,
    private i18nService: I18nService,
    @Inject(AUTH_TOKEN)
    private authToken: string,
    private phraseAppTranslationService: PhraseAppTranslationService,
    private actions$: Actions,
  ) {}

  public init(): void {
    let languageReadyObservables = [this.publicDataLoaded$];

    this.loadInitialPublicData();
    if (this.authToken) {
      this.loadInitialProtectedData();
      languageReadyObservables = [...languageReadyObservables, this.protectedDataLoaded$];
    }

    combineLatest(languageReadyObservables)
      .pipe(first())
      .subscribe(([publicDataLoaded, protectedDataLoaded]) => {
        const isAuthorized = publicDataLoaded && protectedDataLoaded;
        this.resolveTranslations(isAuthorized).subscribe(() => {
          this.router.initialNavigation();
        });
      });
  }

  private loadInitialProtectedData(): void {
    this.store.dispatch(ProfileEntityActions.fetch());
    this.store.dispatch(ConditionsActions.fetchCollection());

    merge(
      this.actions$.pipe(ofType(ProfileEntityActions.fetchSuccess)),
      this.actions$.pipe(ofType(ConditionsActions.fetchCollectionSuccess)),
    ).subscribe(data => this.protectedDataLoaded$.next(true));

    merge(
      this.actions$.pipe(ofType(ProfileEntityActions.fetchFailed)),
      this.actions$.pipe(ofType(ConditionsActions.fetchCollectionFailed)),
    )
      .pipe(take(1))
      .subscribe(({ payload: err }: any) => {
        if (err.status === HTTP_STATUS.UNAUTHORIZED) {
          this.resolveTranslations(false)
            .pipe(filter(Boolean))
            .subscribe(() => {
              this.protectedDataLoaded$.next(false);
              this.store.dispatch(
                CoreEntityActions.toggleExternalAdmin({ payload: { isForeignAdmin: true } }),
              );
              this.router.navigate(['/auth', 'sign_in'], {
                queryParams: {
                  error: 'unauthorized',
                },
              });
            });
        }
      });
  }

  private loadInitialPublicData(): void {
    this.store.dispatch(ProgramEntityActions.fetch());
    this.store.dispatch(TermsActions.fetchCollection());

    combineLatest([
      this.actions$.pipe(ofType(ProgramEntityActions.fetchFailed)),
      this.actions$.pipe(ofType(TermsActions.fetchCollectionFailed)),
    ]).subscribe(() => {
      this.resolveTranslations(false)
        .pipe(filter(Boolean))
        .subscribe(() => {
          this.router.navigate(['/error', HTTP_STATUS.NOT_FOUND]);
          this.router.initialNavigation();
        });
    });

    combineLatest([
      this.actions$.pipe(ofType(ProgramEntityActions.fetchSuccess)),
      this.actions$.pipe(ofType(TermsActions.fetchCollectionSuccess)),
    ]).subscribe(() => {
      this.publicDataLoaded$.next(true);
    });
  }

  private resolveTranslations(isAuthorized: boolean): Observable<PhraseAppTranslations[]> {
    let languageObservable: Observable<SupportedTranslations>;

    if (isAuthorized) {
      languageObservable = this.store.select(profileSelectors.data).pipe(
        filterBoolean,
        map(({ preferredLanguage }) => preferredLanguage),
      );
    } else {
      languageObservable = this.store
        .select(programSelectors.data)
        .pipe(map(data => <SupportedTranslations>(data && data.companyLanguage) || 'en'));
    }

    return languageObservable.pipe(
      switchMap(async language => {
        this.i18nService.locale = language;
        moment.locale(language);
        const translations = await phraseAppTranslationsLoader(language, 'en', [
          appTranslations,
          uiTranslations,
          wysiwygTranslations,
        ]);
        this.phraseAppTranslationService.setTranslations(translations);
        return translations;
      }),
    );
  }
}
