import { HttpErrorResponse } from '@angular/common/http';

import { AuthorisationStrategy, CookieKey, CookieService } from '@recruitee/http';

export class ReferralsAuthStrategy implements AuthorisationStrategy {
  private excludedLocations: string[] = ['sign_in', 'sign_up', 'error', 'confirmation'];
  private excludedUrls: Array<string | RegExp> = [
    /referral_portal\/.*\/profile/,
    /referral_portal\/.*\/conditions/,
    /referral_portal\/.*\/offers\/active/,
  ];

  constructor(
    public appConfig: any,
    private cookieService: CookieService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onAuthorizationFailed(error?: Error): any {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onForbidden(error: Error): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onUnauthorized(error: Error): void {
    this.clearAuthCookies();

    const isLocationExcluded = this.isLocationExcluded();
    const isUrlExcluded = error ? this.isUrlExcluded((<HttpErrorResponse>error).url!) : false;

    if (!isLocationExcluded && !isUrlExcluded) {
      window.location.replace(this.url + 'error/401');
    }
  }

  private clearAuthCookies(): void {
    this.cookieService.delete(CookieKey.SuperadminAccessToken);
    this.cookieService.delete(CookieKey.AccessToken);
    this.cookieService.delete(CookieKey.Company);
  }

  private get url(): string {
    const port = location.port ? `:${location.port}` : '';
    return `${location.protocol}//${location.hostname}${port}/`;
  }

  private isLocationExcluded(): boolean {
    return !!this.excludedLocations.filter(link => window.location.href.includes(link)).length;
  }

  private isUrlExcluded(url: string): boolean {
    return !!this.excludedUrls.find(regexpOrString =>
      typeof regexpOrString === 'string' ? url.includes(regexpOrString) : regexpOrString.test(url),
    );
  }
}
