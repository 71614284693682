import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ApplicationsHttpService } from './applications-http.service';
import { ApplicationsActions } from './applications.store';

@Injectable({ providedIn: 'root' })
export class ApplicationsEffects {
  public loadApplications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationsActions.fetchCollection),
      mergeMap(({ payload }) =>
        this.applicationsHttpService.loadApplications(payload.data).pipe(
          map(data =>
            ApplicationsActions.fetchCollectionSuccess({
              payload: { data },
            }),
          ),
          catchError(error => of(ApplicationsActions.fetchCollectionFailed({ payload: error }))),
        ),
      ),
    ),
  );

  public loadOfferApplications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationsActions.fetchOfferCollection),
      mergeMap(({ payload }) =>
        this.applicationsHttpService.loadOfferApplications(payload.scope, payload.data).pipe(
          map(data =>
            ApplicationsActions.fetchCollectionSuccess({
              payload: { data, scope: `offer_${payload.scope}` },
            }),
          ),
          catchError(error => of(ApplicationsActions.fetchCollectionFailed({ payload: error }))),
        ),
      ),
    ),
  );

  public submitApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationsActions.submitApplication),
      mergeMap(({ payload }) =>
        this.applicationsHttpService.sendApplication(payload).pipe(
          map(data =>
            ApplicationsActions.submitApplicationSuccess({
              payload: { data },
            }),
          ),
          catchError(error => of(ApplicationsActions.submitApplicationFail({ payload: error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private applicationsHttpService: ApplicationsHttpService,
  ) {}
}
