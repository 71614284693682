import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpApiService } from '@recruitee/http';

import { ReferralsPortalProgram, ReferralsPortalProgramPayload } from '../types';

@Injectable({ providedIn: 'root' })
export class ProgramHttpService {
  constructor(private httpApi: HttpApiService) {}

  public loadProgram(): Observable<ReferralsPortalProgram> {
    return this.httpApi.get('/program').pipe(map(res => res.program));
  }

  public updateProgram(payload: ReferralsPortalProgramPayload): Observable<ReferralsPortalProgram> {
    return this.httpApi.patch('/program', null, { program: payload }).pipe(map(res => res.program));
  }
}
