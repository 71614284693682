import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ReferrerHttpService } from './referrer-http.service';
import { ReferrerEntityActions } from './referrer.store';

@Injectable({ providedIn: 'root' })
export class ReferrerEffects {
  public forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReferrerEntityActions.forgotPassword),
      mergeMap(({ payload }) =>
        this.referrerHttpService.forgotPassword(payload).pipe(
          map(() => ReferrerEntityActions.forgotPasswordSuccess()),
          catchError(error => of(ReferrerEntityActions.forgotPasswordFail({ payload: error }))),
        ),
      ),
    ),
  );

  public resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReferrerEntityActions.resetPassword),
      mergeMap(({ payload }) =>
        this.referrerHttpService.resetPassword(payload).pipe(
          map(() => ReferrerEntityActions.resetPasswordSuccess()),
          catchError(error => of(ReferrerEntityActions.forgotPasswordFail({ payload: error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private referrerHttpService: ReferrerHttpService,
  ) {}
}
