import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CoreEffects } from './store/core-effects.service';
import { CoreNamespace, coreReducer } from './store/core.store';

@NgModule({
  imports: [
    StoreModule.forFeature(CoreNamespace, coreReducer),
    EffectsModule.forFeature([CoreEffects]),
  ],
})
export class CoreDataModule {}
