import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpApiService } from '@recruitee/http';

import { FeatureDiscoveryPayloadI } from '../types';

@Injectable({ providedIn: 'root' })
export class FeatureDiscoveryHttpService {
  constructor(private httpApi: HttpApiService) {}

  public markFeatureAsDiscovered(payload: FeatureDiscoveryPayloadI): Observable<void> {
    return this.httpApi.patch('/feature_discovery', null, { featureDiscovery: payload });
  }
}
