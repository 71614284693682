import { InjectionToken } from '@angular/core';

// referrals-fb9e573a72 will be replaced during build step,
// it is used by sentry to match sourcemaps

export const AppRevisionToken = new InjectionToken('APP_REVISION');
export const AppRevisionProvider = {
  provide: AppRevisionToken,
  useValue: `referrals-fb9e573a72`,
};
