import { AuthHelpers, AuthService, CookieService } from '@recruitee/http';

import { appConfig, languageConfig } from './app/config';
import { ReferralsAuthStrategy } from './referrals-auth-strategy';

const cookieService = new CookieService();
const authHelpers = new AuthHelpers(cookieService);
export const authStrategy = new ReferralsAuthStrategy(appConfig, cookieService);
export const authService = new AuthService(
  authStrategy,
  authHelpers,
  cookieService,
  languageConfig,
);
