import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpApiService } from '@recruitee/http';
import { PaginationState } from '@recruitee/ngrx';
import type { ReferralFilterOptions, ReferralOfferAssignment } from '@recruitee/referrals-types';

import { FilterSet } from './offer-assignments.store';

@Injectable({ providedIn: 'root' })
export class OfferAssignmentsHttpService {
  constructor(private httpApi: HttpApiService) {}

  public loadOfferAssignments(params: FilterSet): Observable<{
    offerAssignments: ReferralOfferAssignment[];
    meta: PaginationState;
  }> {
    return this.httpApi.get('/offers/active', params);
  }

  public loadOfferAssignmentDetails(offerId: number): Observable<{
    offerAssignment: ReferralOfferAssignment;
  }> {
    return this.httpApi.get(`/offers/${offerId}`);
  }

  public loadOfferFilters(): Observable<ReferralFilterOptions> {
    return this.httpApi.get(`/offers/filters`);
  }
}
