import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';

import { Subject } from 'rxjs';

import { PlatformThemeService } from '@recruitee/user-interface';

import { AppInitializationService } from './app-initialization.service';

/* eslint-disable @angular-eslint/prefer-standalone */
@Component({
  selector: 'rtr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit, OnDestroy {
  private ngOnDestroy$ = new Subject<void>();

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private appInitializationService: AppInitializationService,
    private themeSrvc: PlatformThemeService,
  ) {
    this.renderer.addClass(document.body, `browser-${this.themeSrvc.resolveBrowserClassname()}`);
  }

  public ngOnInit(): void {
    this.appInitializationService.init();
  }

  public ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }
}
