import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpApiService } from '@recruitee/http';

import { ReferralsCondition } from '../types';

@Injectable({ providedIn: 'root' })
export class ConditionsHttpService {
  constructor(private httpApi: HttpApiService) {}

  public loadConditions(): Observable<ReferralsCondition[]> {
    return this.httpApi.get('/conditions').pipe(map(data => data.conditions));
  }
}
