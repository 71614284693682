import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ConditionsEffects } from './store/conditions-effects.service';
import { ConditionsNamespace, conditionsReducer } from './store/conditions.store';

@NgModule({
  imports: [
    EffectsModule.forFeature([ConditionsEffects]),
    StoreModule.forFeature(ConditionsNamespace, conditionsReducer),
  ],
})
export class ConditionsDataModule {}
