import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';

import { MainViewNamespace, mainViewReducer } from './store/main-view.store';

@NgModule({
  imports: [StoreModule.forFeature(MainViewNamespace, mainViewReducer)],
})
export class MainViewDataModule {}
