import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ProfileHttpService } from './profile-http.service';
import { ProfileEntityActions } from './profile.store';

@Injectable({ providedIn: 'root' })
export class ProfileEffects {
  public loadProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileEntityActions.fetch),
      mergeMap(() =>
        this.profileHttpService.loadProfile().pipe(
          map(data => ProfileEntityActions.fetchSuccess({ payload: data })),
          catchError(error => of(ProfileEntityActions.fetchFailed({ payload: error }))),
        ),
      ),
    ),
  );

  public updateProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileEntityActions.updateProfile),
      mergeMap(({ payload }) =>
        this.profileHttpService.updateProfile(payload).pipe(
          map(data => ProfileEntityActions.updateProfileSuccess({ payload: data })),
          catchError(error => of(ProfileEntityActions.updateProfileFail({ payload: error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private profileHttpService: ProfileHttpService,
  ) {}
}
