import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ProgramHttpService } from './program-http.service';
import { ProgramEntityActions } from './program.store';

@Injectable({ providedIn: 'root' })
export class ProgramEffects {
  public loadProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProgramEntityActions.fetch),
      mergeMap(() =>
        this.programHttpService.loadProgram().pipe(
          map(data => ProgramEntityActions.fetchSuccess({ payload: data })),
          catchError(error => of(ProgramEntityActions.fetchFailed({ payload: error }))),
        ),
      ),
    ),
  );

  public updateProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProgramEntityActions.updateProgram),
      mergeMap(({ payload }) =>
        this.programHttpService
          .updateProgram(payload)
          .pipe(map(data => ProgramEntityActions.updateProgramSuccess({ payload: data }))),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private programHttpService: ProgramHttpService,
  ) {}
}
