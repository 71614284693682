import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ProgramEffects } from './store/program-effects.service';
import { ProgramNamespace, programReducer } from './store/program.store';

@NgModule({
  imports: [
    StoreModule.forFeature(ProgramNamespace, programReducer),
    EffectsModule.forFeature([ProgramEffects]),
  ],
})
export class ProgramDataModule {}
